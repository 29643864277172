import {AddMarkStep, RemoveMarkStep, ReplaceAroundStep, ReplaceStep, Step} from '@tiptap/pm/transform';
import {EditorView} from '@tiptap/pm/view';
import {AllSelection, NodeSelection, Selection, TextSelection, Transaction} from '@tiptap/pm/state';
import {Slice} from '@tiptap/pm/model';
import {debugPrintProseMirrorStructure} from '@/components/applicationEditor/utils/debug.util';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import {UserInputPlugin} from '@/components/applicationEditor/plugins/UserInputPlugin';

export type KnownStep = ReplaceStep
  | ReplaceAroundStep
  | AddMarkStep
  | RemoveMarkStep;

export function isKnownStep(step: Step): step is KnownStep {
  return step instanceof ReplaceStep
    || step instanceof ReplaceAroundStep
    || step instanceof AddMarkStep
    || step instanceof RemoveMarkStep;
}

// Do not process correct transactions annotated with this meta keys
export type DoNotProcessInBlockNodeDeletionPluginMetaKeysType = ProsemirrorTransactionMeta.INITIAL_STATE
  | ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN
  | ProsemirrorTransactionMeta.SPELLCHECK_RESULT
  | ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR
  | ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND
  | ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE;

export const DoNotProcessInBlockNodeDeletionPluginMetaKeys: DoNotProcessInBlockNodeDeletionPluginMetaKeysType[] = [
  ProsemirrorTransactionMeta.INITIAL_STATE,
  ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN,
  ProsemirrorTransactionMeta.SPELLCHECK_RESULT,
  ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR,
  ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND,
  ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE,
];

export function filterForUnknownSteps(transaction: Transaction): Step[] {
  return transaction.steps.filter((step: Step) => !isKnownStep(step));
}

export function hasNotReplicatedStep(transaction: Transaction) {
  return filterForUnknownSteps(transaction).length > 0;
}

export function syncEditors(sourceEditor: EditorView, targetEditor: EditorView, transaction: Transaction): boolean {

  const debug = false;

  const log = (msg: string, ...additionalOutput: any) => {
    if (debug) {
      console.log(msg, additionalOutput);
    }
  }

  if(transaction.getMeta(ProsemirrorTransactionMeta.REPLICATED_TRANSACTION)){
    log('transaction already replicated from other editor');
    return true;
  }

  if(!UserInputPlugin.isWhitelisted(transaction, sourceEditor.state)){
    return true;
  }

  const replicatedTransaction = targetEditor.state.tr;
  log('start replication');

  const nonReplicatedSteps = filterForUnknownSteps(transaction);

  if (nonReplicatedSteps.length > 0) {
    log('There are steps that cant be replicated.', nonReplicatedSteps);
    return false;
  }

  transaction.steps
    .filter(isKnownStep)
    .forEach((step: KnownStep) => {
      if (step instanceof ReplaceStep) {
        const replacestep = step as ReplaceStep;
        const adaptedSlice = Slice.fromJSON(targetEditor.state.schema, replacestep.slice.toJSON()!);
        const newReplaceStep = new ReplaceStep(replacestep.from, replacestep.to, adaptedSlice);
        log('replacing', replacestep, newReplaceStep);
        const {doc, failed} = replicatedTransaction.maybeStep(newReplaceStep);
        if (failed) {
          const s = debugPrintProseMirrorStructure(sourceEditor.state.doc).reduce(
            (accumulator, currentValue) => accumulator + currentValue +
              '\n', '');
          const t = debugPrintProseMirrorStructure(targetEditor.state.doc).reduce(
            (accumulator, currentValue) => accumulator + currentValue +
              '\n', '');
          log(s);
          log(t);
          log(`1 - could not replicate step: ${step.toJSON()}`);
          return false;
        }
      } else if (step instanceof ReplaceAroundStep) {
        const replaceAroundStep = step as ReplaceAroundStep;
        const adaptedSlice = Slice.fromJSON(targetEditor.state.schema, replaceAroundStep.slice.toJSON()!);
        const newReplaceAroundStep = new ReplaceAroundStep(replaceAroundStep.from, replaceAroundStep.to,
                                                           replaceAroundStep.gapFrom, replaceAroundStep.gapTo,
                                                           adaptedSlice,
                                                           replaceAroundStep.insert,
                                                           (replaceAroundStep as any).structure);
        const {doc, failed} = replicatedTransaction.maybeStep(newReplaceAroundStep);
        if (failed) {
          log(`2 - could not replicate step: ${step.toJSON()}`);
          return false;
        }
      } else if (step instanceof AddMarkStep) {
        const addMarkStep = step as AddMarkStep;
        const replicatedAddMarkStep = new AddMarkStep(addMarkStep.from, addMarkStep.to, addMarkStep.mark);
        const {doc, failed} = replicatedTransaction.maybeStep(replicatedAddMarkStep);
        if (failed) {
          log(`3 - could not replicate step: ${step.toJSON()}`);
          return false;
        }
      } else if (step instanceof RemoveMarkStep) {
        const removeMarkStep = step as RemoveMarkStep;
        const replicatedRemoveMarkStep = new AddMarkStep(removeMarkStep.from, removeMarkStep.to, removeMarkStep.mark);
        const {doc, failed} = replicatedTransaction.maybeStep(replicatedRemoveMarkStep);
        if (failed) {
          log(`4 - could not replicate step: ${step.toJSON()}`);
          return false;
        }
      } else {
        const exhaustiveCheck: never = step;
        throw new Error(`Unhandled step: ${exhaustiveCheck}`);
      }
    });

  const currentSelection = transaction.selection;
  let targetSelection: Selection | null = null;
  if (currentSelection instanceof TextSelection) {
    const textSelection = currentSelection as TextSelection
    targetSelection = TextSelection.create(replicatedTransaction.doc, textSelection.$anchor.pos, textSelection.$head.pos);
  } else if (currentSelection instanceof AllSelection) {
    targetSelection = new AllSelection(replicatedTransaction.doc);
  } else if (currentSelection instanceof NodeSelection) {
    targetSelection = NodeSelection.create(replicatedTransaction.doc, currentSelection.$from.pos);
  }

  if (targetSelection) {
    replicatedTransaction.setSelection(targetSelection);
  }

  // TODO (jdo): Check if these are still needed
  DoNotProcessInBlockNodeDeletionPluginMetaKeys.forEach((key) => {
    const value = transaction.getMeta(key);
    if (value) {
      replicatedTransaction.setMeta(key, value);
    }
  });

  replicatedTransaction.setMeta(ProsemirrorTransactionMeta.REPLICATED_TRANSACTION, true);
  replicatedTransaction.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
  log('Applying replicated transaction')
  targetEditor.dispatch(replicatedTransaction);
  return true;
}
