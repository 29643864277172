import { resolveComponent as _resolveComponent, vShow as _vShow, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3aea353e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "editorMenuBar",
  class: "menubar"
}
const _hoisted_2 = { class: "menubar-row" }
const _hoisted_3 = { class: "create-delete-button-container" }
const _hoisted_4 = { class: "generate-undo-redo" }
const _hoisted_5 = { class: "generate-button-container" }
const _hoisted_6 = { class: "menubar-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateBlock = _resolveComponent("CreateBlock")!
  const _component_DeleteBlock = _resolveComponent("DeleteBlock")!
  const _component_InlineBlock = _resolveComponent("InlineBlock")!
  const _component_GenerateBlock = _resolveComponent("GenerateBlock")!
  const _component_GenerateAll = _resolveComponent("GenerateAll")!
  const _component_History = _resolveComponent("History")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_PatentEngineMenuBar = _resolveComponent("PatentEngineMenuBar")!

  return (_openBlock(), _createBlock(_component_PatentEngineMenuBar, { editor: _ctx.editorInstance }, {
    default: _withCtx(({ commands }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "space-between-container",
            style: _normalizeStyle(_ctx.visible)
          }, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createBlock(_component_CreateBlock, {
                key: _ctx.computedActiveEditorSelection+1,
                editor: _ctx.editorInstance
              }, null, 8, ["editor"])), [
                [_vShow, _ctx.selectedBlockGuid]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_DeleteBlock, {
                key: _ctx.computedActiveEditorSelection+2,
                editor: _ctx.editorInstance
              }, null, 8, ["editor"])), [
                [_vShow, _ctx.selectedBlockGuid]
              ]),
              _withDirectives((_openBlock(), _createBlock(_component_InlineBlock, {
                key: _ctx.computedActiveEditorSelection+3,
                editor: _ctx.editorInstance
              }, null, 8, ["editor"])), [
                [_vShow, _ctx.selectedBlockGuid]
              ])
            ])
          ], 4),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createVNode(_component_GenerateBlock, { editor: _ctx.editorInstance }, null, 8, ["editor"]), [
                [_vShow, _ctx.selectedBlockGuid]
              ])
            ]),
            _createVNode(_component_GenerateAll, _mergeProps({ editor: _ctx.editor }, {commands}), null, 16, ["editor"]),
            _createVNode(_component_History, {
              editor: _ctx.editor,
              applicationeditor: _ctx.applicationeditor
            }, null, 8, ["editor", "applicationeditor"])
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_6, [
          _createVNode(_component_SearchBar, {
            ref: "searchBar",
            editor: _ctx.editor,
            closeCallback: _ctx.closeSearchbarPressed
          }, null, 8, ["editor", "closeCallback"])
        ], 512), [
          [_vShow, _ctx.toggleSearchBar]
        ])
      ], 512)
    ]),
    _: 1
  }, 8, ["editor"]))
}