import {Plugin, PluginKey, Transaction as PmTransaction} from '@tiptap/pm/state';
import {Step as PmStep} from '@tiptap/pm/transform';
import {Fragment, Node as PmNode, Slice} from '@tiptap/pm/model';
import {determineDocStartPos} from '@/components/applicationEditor/utils/prosemirror.util';
import EditorModule from '@/store/modules/EditorModule';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';

// Do not process correct transactions annotated with this meta keys
export type DoNotProcessInBackendSynchronisationPluginMetaKeysType = ProsemirrorTransactionMeta.INITIAL_STATE
  | ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN
  | ProsemirrorTransactionMeta.SPELLCHECK_RESULT
  | ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR
  | ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND
  | ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE;

export const DoNotProcessInBackendSynchronisationPluginMetaKeys: DoNotProcessInBackendSynchronisationPluginMetaKeysType[] = [
  ProsemirrorTransactionMeta.INITIAL_STATE,
  ProsemirrorTransactionMeta.PATENTENGINE_HISTORY_PLUGIN,
  ProsemirrorTransactionMeta.SPELLCHECK_RESULT,
  ProsemirrorTransactionMeta.UPDATE_ACTIVE_EDITOR,
  ProsemirrorTransactionMeta.UPDATE_FROM_BACKEND,
  ProsemirrorTransactionMeta.UPDATE_LOGICAL_DEPTH_ATTRIBUTE,
];

const BackendSynchronisationPluginKey = new PluginKey('BackendSynchronisationPlugin');

export class BackendSynchronisationPlugin extends Plugin {

  constructor() {
    super({
            key: BackendSynchronisationPluginKey,
            appendTransaction(transactions, oldState, newState) {
              transactions.forEach(transaction => {
                if (DoNotProcessInBackendSynchronisationPluginMetaKeys.some(key => transaction.getMeta(key) !== undefined)) {
                  return;
                }
                transaction.steps.forEach(step => {
                  if (BackendSynchronisationPlugin.isAddMarkStep(step) || BackendSynchronisationPlugin.isRemoveMarkStep(step)) {
                    BackendSynchronisationPlugin.storeInvolvedTextnode(transaction.before, step);
                    return;
                  }

                  BackendSynchronisationPlugin.saveStepChanges(step, transaction);
                })
              })
              return newState.tr;
            },
    });
  }

  public static saveStepChanges(step: PmStep, transaction: PmTransaction): void {

    // Determine the complete text of this step
    const slice: Slice = (step as any).slice;
    const fragment: Fragment = slice.content;
    const stepNodes: Array<PmNode> = (fragment as any).content;
    const stepText: string = stepNodes.reduce((accumulator: string, node: PmNode) => accumulator + node.textContent, '');

    step.getMap().forEach((oldStart: number, oldEnd: number, newStart: number, newEnd: number) => {
      const docStartPos = determineDocStartPos(transaction.doc);

      // First make sure this step's position refers to a position inside of the text blocks we can edit
      // On GenerateAll Prosemirror may trigger a transaction with oldStart = 1 if the document has a figure.
      if (oldStart >= docStartPos) {
        const resolvedPos = transaction.doc.resolve(oldStart);
        const replaceNode = resolvedPos.node();

        let leavesText = '';
        BackendSynchronisationPlugin.findLeafs(replaceNode).forEach((leaf) => {
          leavesText += leaf.textContent;
        });

        // Special case: Check if the texts are the same:
        // After generating (all) blocks prosemirror might trigger transactions
        // that would lead to replacements with oneself (maybe only for the read only block SHORT_DESCRIPTION_FIGURE_TEXT)
        if (stepText !== leavesText) {
          // User/Prosemirror has done something "normal" or a replace(All)
          // Just store the GUID of the node in the store, such that we can later persist the change in the backend
          // Special case needed for search & replace -> Take all children (leaves)
          // Attention, this can rarely (deterministic) lead to saving more than needed or even the whole document.
          // It may depend on from where to where we replace, depending on whether these replacements start or end at block boundaries, a
          // resolvedPos.node() can return a too high father node or even the root. Since you cannot see which of the children is affected,
          // this can happen.
          BackendSynchronisationPlugin.findLeafs(replaceNode).forEach((leaf) => {
            EditorModule.addChange(leaf.attrs.guid);
          });
        }
      }
    });
  }

  public static storeInvolvedTextnode(root: PmNode, step: PmStep): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const from: number = step['from'];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const to: number = step['to'];
    root.nodesBetween(from, to, (node: PmNode) => {
      if (node.isTextblock && node.attrs.guid) {
        EditorModule.addChange(node.attrs.guid);
      }
      return true;
    });
  }

  public static findLeafs(node: PmNode): PmNode[] {
    if (node.isTextblock) {
      return [node];
    }
    let nodes: PmNode[] = [];
    for (let childIndex = 0; childIndex < node.childCount; childIndex++) {
      nodes = nodes.concat(BackendSynchronisationPlugin.findLeafs(node.child(childIndex)));
    }
    return nodes;
  }

  public static isAddMarkStep(step: PmStep): boolean {
    return step.toJSON().stepType === 'addMark';
  }

  public static isRemoveMarkStep(step: PmStep): boolean {
    return step.toJSON().stepType === 'removeMark';
  }
}