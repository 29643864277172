import {CommandProps, Extension} from '@tiptap/vue-3';
import {Editor as CoreEditor} from '@tiptap/core';
import {Decoration, DecorationSet} from '@tiptap/pm/view';
import {EditorState, Plugin, Transaction} from '@tiptap/pm/state';
import {Node as PmNode} from '@tiptap/pm/model';
import EditorModule from '@/store/modules/EditorModule';
import {MergedTextNode, SearchAndReplaceResult} from '@/store/models/searchAndReplace.model';
import {ApplicationEditor as ApplicationEditorClass} from '@/components/ApplicationEditor.vue';
import {calcGuidOfLogicalBlock, findDepthOfLogicalBlock} from '@/components/applicationEditor/utils/node.util';
import {ProsemirrorTransactionMeta} from '@/components/common/prosemirror.enums';
import {nextTick} from 'vue';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    searchAndReplaceExtension: {
      find: (attrs: { searchTerm: string | null }) => ReturnType,
      replace: (attrs: { replace: string; searchTerm: string; callback?: (success: boolean) => void }) => ReturnType,
      replaceAll: (attrs: { replace: string; searchTerm: string; callback?: (success: boolean) => void }) => ReturnType,
      clearSearch: () => ReturnType
    }
  }
}

export interface SearchAndReplaceExtensionData {
  results: SearchAndReplaceResult[],
  currentSearchTerm: (string | null),
  lastSearchTerm: (string | null),
  _updating: boolean,
  currentSelection: (number | undefined),
  scrolled: boolean,
  firstFindingResult: (SearchAndReplaceResult | null),
  firstFindingBorderResults: (SearchAndReplaceResult[]),
  divideNodes: true, // If true, search block wise
  checkOverBorders: true // If true, also add parts of results that are in other text blocks
}

export const initialData: SearchAndReplaceExtensionData = {
  results: [],
  currentSearchTerm: null,
  lastSearchTerm: null,
  _updating: false,
  currentSelection: 0,
  scrolled: false,
  firstFindingResult: null,
  firstFindingBorderResults: [],
  divideNodes: true, // If true, search block wise
  checkOverBorders: true // If true, also add parts of results that are in other text blocks
}

/**
 * Extension for searching and replacing and to open the search bar via key combination
 */
export const SearchAndReplaceExtension = (applicationEditorInstance: ApplicationEditorClass, options: any) => {

  const extendedOptions = {
    ...options,
    autoSelectNext: true,
    findClass: 'find',
    findCurrentClass: 'find-current',
    searching: false,
    caseSensitive: false,
    disableRegex: true,
    alwaysSearch: false
  };

  const findRegExp = (data: SearchAndReplaceExtensionData): RegExp => {
    // Use replace to match the metacharacters literally and escape special meaning
    return RegExp(data.currentSearchTerm?.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') as string, !extendedOptions.caseSensitive ? 'gui' : 'gu')
  };

  const decorations = (data: SearchAndReplaceExtensionData): Decoration[] => {
    return data.results.map(deco => (
      Decoration.inline(deco.from, deco.to, {
        // The current finding (next after the current cursor position) has a darker color
        class: deco.isCurrentFinding ? extendedOptions.findCurrentClass : extendedOptions.findClass
      })
    ))
  };

  /**
   * This function iterates trough the whole document.
   * It skips irrelevant nodes like placeholders.
   * It merges text blocks that follow on each other, without a block in between that would have to be ignored, to improve performance.
   * But the regex test itself must be done on the hole text, because we want to be able to find text over text block borders.
   * The merged text nodes can be used to find the correct positions, like we didn't skip any nodes.
   * If a search result crosses text block borders, we call checkResultsOverBorders that iterates all following blocks until the end is
   * reached. To do that efficiently we also hold the blockBorders list with all border positions.
   * Furthermore we want to highlight the current finding (the next one after the current cursor position) in an more intense color,
   * compared to the other findings ($pengine-editor-find vs. $pengine-editor-find-current). To realize that we read the
   * currentSelection from the editor and compare it to our findings' positions.
   * A special case occures when the selection is after the last search result, so we have select the first finding in the document as
   * the current one. To be able to do that, we need to store the first search result (firstFindingResult) but also all corresponding
   * firstFindingBorderResults.
   *
   * @param doc The document root node
   */
  const _search = (data: SearchAndReplaceExtensionData, doc: PmNode): void => {
    if (data.currentSearchTerm == null) {
      return;
    }

    data.results = [];
    let mergedTextNodes: MergedTextNode[] = [];
    const blockBorders: number[] = [];
    let index = 0;
    let newBlock = false;
    const textParts: string[] = [];
    let textPartIndex = 0;
    let currentLogicalBlockGuid: string | null = null;

    // Collect text by merging nodes we want to check
    doc.descendants((node, pos, parent) => {
      if (node.isText && !parent?.attrs.isReadOnly) {
        if (newBlock) {
          newBlock = false;
          blockBorders.push(pos);
        }

        const text = node.text as string;
        if (mergedTextNodes[index]) {
          mergedTextNodes[index] = {
            text: mergedTextNodes[index].text + node.text,
            pos: mergedTextNodes[index].pos
          }
        } else {
          mergedTextNodes[index] = {
            text: text,
            pos
          }
        }
        const logicalBlockGuid = calcGuidOfLogicalBlock(doc, pos);
        if (logicalBlockGuid != null) {
          if (currentLogicalBlockGuid === null) {
            currentLogicalBlockGuid = logicalBlockGuid;
          } else if (currentLogicalBlockGuid !== logicalBlockGuid) {
            currentLogicalBlockGuid = logicalBlockGuid;
            textPartIndex++;
          }
        }
        textParts[textPartIndex] = textParts[textPartIndex] ? (textParts[textPartIndex] + text) : text;

      } else {
        if (data.divideNodes) {
          index++;
        }
        newBlock = true;
      }
    })

    // Search the merged nodes and scroll to the next finding after the current position
    data.scrolled = false;
    data.firstFindingResult = null;

    checkCursorPosition(data);

    // We only need merged nodes that hold content (alternative that also ignores whitespaces only: part.text.match(/^ *$/) == null)
    mergedTextNodes = mergedTextNodes.filter((part) => part.text);

    // Call the heart of the search
    _searchMergedNodes(data, mergedTextNodes, textParts, blockBorders);

    // If we reached the last finding, jump back to the first one
    if (!data.scrolled && data.firstFindingResult) {
      (data.firstFindingResult as SearchAndReplaceResult).isCurrentFinding = true;
      scrollToFinding((data.firstFindingResult as SearchAndReplaceResult).to);

      // If there were results over the text block borders, we need to mark them as current finding, too
      data.firstFindingBorderResults.forEach((borderResult) => borderResult.isCurrentFinding = true);
    }
  };

  // Check the current cursor position in the editor and remember it
  const checkCursorPosition = (data: SearchAndReplaceExtensionData) => {
    data.currentSelection = applicationEditorInstance.activeEditor?.state.selection.from;
    if (data.currentSelection == null) {
      data.currentSelection = 0;
    }
  }

  const _searchMergedNodes = (data: SearchAndReplaceExtensionData, mergedNodes: MergedTextNode[], textParts: string[], blockBorders: number[]): void => {
    if (mergedNodes.length === 0) {
      return;
    }

    const search = findRegExp(data);
    let regExpResults: RegExpExecArray | null;

    let mergedNodeIndex = 0;
    let currentMergedNode = mergedNodes[0];
    let textLength = currentMergedNode.text.length;
    let totalTextPartsLength = 0;
    let offset = currentMergedNode.pos;
    data.firstFindingBorderResults = [];

    // Single main blocks (logical blocks) for the regExp
    textParts.forEach((textPart: string) => {

      // eslint-disable-next-line no-cond-assign
      while ((regExpResults = search.exec(textPart))) {
        if (regExpResults[0] === '') {
          break;
        }

        // While the beginning of the result is not in our text included, iterate the merged nodes
        const resultIndex = regExpResults.index + totalTextPartsLength;
        while (resultIndex >= textLength) {
          currentMergedNode = mergedNodes[++mergedNodeIndex];
          offset = currentMergedNode.pos - textLength; // Keep track of the position including the ignored stuff by using this offset
          textLength += currentMergedNode.text.length;
        }

        // We will ignore every match with textblocks that are empty (or only contain one whitespace)
        if (currentMergedNode.text.match(/^ *$/) != null) {
          continue;
        }

        // Now we can determine the positions
        const from = offset + resultIndex;
        const to = from + regExpResults[0].length;
        const result: SearchAndReplaceResult = {from, to, toOriginal: to, isCurrentFinding: false, isFirstPart: true};
        let storeBorderResults = false;

        // Check if we should scroll to this result
        if (!data.scrolled) {
          if (from >= (data.currentSelection as number)) {
            // Scroll to position
            scrollToFinding(to);
            result.isCurrentFinding = true;
            data.scrolled = true;
          } else if (data.firstFindingResult == null) {
            // else remember the first finding - we may start over from the top
            data.firstFindingResult = result;
            storeBorderResults = true; // We must remember the results over text block borders
          }
        }

        data.results.push(result);

        // Check if the result is devided in multiple blocks
        if (data.checkOverBorders) {
          const borderResults: SearchAndReplaceResult[] = checkResultsOverBorders(data, blockBorders, from, to, result.isCurrentFinding);
          // Let the main result have references to its other result parts
          result.borderResults = borderResults;
          // And shorten the own range
          if (borderResults.length) {
            result.to = borderResults[0].from - 1;
          }
          if (storeBorderResults) {
            data.firstFindingBorderResults = borderResults;
          }
        }
      }
      totalTextPartsLength += textPart.length;
    });
  };

  const checkResultsOverBorders = (data: SearchAndReplaceExtensionData, blockBorders: number[], from: number, to: number, isCurrentFinding: boolean): SearchAndReplaceResult[] => {
    let shift = 2; // Distance between each blocks
    const borderResults: SearchAndReplaceResult[] = [];
    for (let borderIndex = 0; borderIndex < blockBorders.length; borderIndex++) {
      const borderPos = blockBorders[borderIndex];

      // Just ignore all borders up to the current result
      if (borderPos <= from) {
        continue;
      }

      // When we are over the result, delete the first borders, because we don't need to check them again
      if (borderPos >= to + shift) {
        blockBorders.splice(0, borderIndex);
        break;
      }

      // If we end up here we found a text block border in between the result, so we create a new decoration
      const result = {from: borderPos, to: to + shift, toOriginal: to, isCurrentFinding: isCurrentFinding, isFirstPart: false};
      data.results.push(result);
      // Shorten previous parts
      if (borderResults.length > 0) {
        borderResults[borderResults.length - 1].to = result.from - 1;
      }
      borderResults.push(result);
      // This must be adapted if we also want to make it work with bigger borders (multiple empty blocks in between or another main block)
      shift += 2;
    }

    // Return the border results for the case we have to mark them as current finding later, when we start over at the document beginning
    // and we want to be able to link the main result to its other result parts
    return borderResults;
  };

  /**
   * Scroll to a found search result and set the text cursor behind the found word(s).
   * @param to The end position of the search result to scroll to
   */
  const scrollToFinding = (to: number): void => {
    const resolvedPos = applicationEditorInstance.activeEditor?.state.doc.resolve(to);
    if (resolvedPos != null) {
      const depth = findDepthOfLogicalBlock(resolvedPos);
      if (depth < 0) {
        return;
      }
      const block = resolvedPos.node(depth);
      EditorModule.selectGuidForEditor(block.attrs.guid);

      // Let's give the editor selection some time and then set the text cursor behind the found word
      nextTick(() => {
        nextTick(() => {
          if (applicationEditorInstance.activeEditor) {
            applicationEditorInstance.activeEditor.commands.focus(to);
          }
        });
      });
    }
  };

  const searchIfNoResults = (data: SearchAndReplaceExtensionData, searchTerm: string, editor: CoreEditor): boolean => {
    // use last times search result if search term has not changed meanwhile
    const newSearchRequired = !data.results.length || searchTerm !== data.lastSearchTerm;
    if (newSearchRequired && editor) {
      editor.commands.find({searchTerm: searchTerm});
      data.lastSearchTerm = searchTerm;
      return true;
    }
    return false;
  };

  const updateView = (data: SearchAndReplaceExtensionData, tr: Transaction, dispatch: ((args?: any) => any)) => {
    data._updating = true;
    tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'SearchAndReplaceExtension - updateView');
    tr.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
    dispatch(tr);
  };

  const createDeco = (data:SearchAndReplaceExtensionData, doc: PmNode): DecorationSet => {
    // _search(data, doc);
    return decorations(data)
      ? DecorationSet.create(doc, decorations(data))
      : DecorationSet.create(doc, []);
  };

  // Because of replacing a result with other text, the following result positions may get shiftet - here we fix this
  const rebaseNextResult = (data: SearchAndReplaceExtensionData, replace: string, index: number): void => {
    let nextIndex = index + 1;

    if (!data.results[nextIndex]) {
      return;
    }
    // We need to find the next main result
    while (!data.results[nextIndex].isFirstPart) {
      nextIndex++;
      if (!data.results[nextIndex]) {
        return;
      }
    }

    const currentResult = data.results[index];
    const nextResult = data.results[nextIndex];
    let resultsToShift = [nextResult];
    // We also have to shift its other parts
    if (nextResult.borderResults) {
      resultsToShift = resultsToShift.concat(nextResult.borderResults);
    }

    const offset = currentResult.toOriginal - currentResult.from - replace.length;

    // Seems like we only replaced text with text of the same length so far
    if (offset == 0) {
      return;
    }

    resultsToShift.forEach((nextResult) => {
      nextResult.from -= offset;
      nextResult.to -= offset;
    })
  };

  const replaceToTransaction = (tr: Transaction, replace: string, result: SearchAndReplaceResult) => {
    tr.setMeta(ProsemirrorTransactionMeta.IS_SEARCH_REPLACEMENT, true);

    // Simple case: everything to replace is only in one block
    if (!result.borderResults || !result.borderResults.length) {
      tr.insertText(replace, result.from, result.to);
      return;
    }

    // Else we need to find a way to split the result into the text blocks
    const replacements = replace.split(' ');
    const resultParts = [result].concat(result.borderResults);

    // We count how many words each block gets
    const wordsPerBlock: number[] = new Array(resultParts.length).fill(0);
    for (let wordIndex = 0; wordIndex < replacements.length; wordIndex++) {
      wordsPerBlock[wordIndex % resultParts.length]++;
    }

    let replacementIndex = replacements.length - 1;
    // Go through all blocks (/ results). Backwards, so we don't have to adapt position
    for (let blockIndex = wordsPerBlock.length - 1; blockIndex >= 0; blockIndex--) {
      let resultForBlock = '';
      if (wordsPerBlock[blockIndex] != 0) {
        let wordsNumber = wordsPerBlock[blockIndex];
        while (wordsNumber > 0) {
          // If we already have text, add a space on the left
          if (resultForBlock !== '') {
            resultForBlock = ' ' + resultForBlock;
          }
          resultForBlock = replacements[replacementIndex--] + resultForBlock;
          wordsNumber--;
        }
        // Add a space if this is not the first block
        if (blockIndex > 0) {
          resultForBlock = ' ' + resultForBlock;
        }
      }
      const resultPart = resultParts[blockIndex];
      // If a text block becomes empty, we delete it
      if (resultForBlock === '') {
        tr.delete(resultPart.from, resultPart.to);
      } else {
        // Else we replace the text
        tr.insertText(resultForBlock, resultPart.from, resultPart.to);
      }
    }
  };

  const find = (data: SearchAndReplaceExtensionData, attrs: { searchTerm: string | null }) => {
    return ({tr, state, dispatch}: CommandProps): boolean => {

      if (!dispatch) {
        return false;
      }
      data.currentSearchTerm = extendedOptions.disableRegex
        ? (attrs.searchTerm && attrs.searchTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')) || null
        : attrs.searchTerm;

      _search(data, tr.doc);

      updateView(data, tr, dispatch);
      return true;
    }
  };

  const replace = (data: SearchAndReplaceExtensionData, attrs: { replace: string; searchTerm: string; callback?: (success: boolean) => void }, editor: CoreEditor) => {
    // If there are no search results yet, trigger the search first.
    const searchTriggered = searchIfNoResults(data, attrs.searchTerm, editor);
    if (!searchTriggered) {
      // The search would update the variable holding the current curosr position. Else we do it manually.
      checkCursorPosition(data);
    }

    return ({tr, dispatch}: { tr: Transaction, dispatch: ((args?: any) => any) | undefined }): boolean => {
      if (!data.results.length || !editor || !dispatch) {
        if (attrs.callback) {
          attrs.callback(false);
        }
        return false;
      }

      // Find the first result that ends after the current cursor position
      let resultIndex = 0;
      let result = data.results[resultIndex];
      if (data.currentSelection != undefined) {
        while (result.toOriginal < data.currentSelection) {
          resultIndex++;
          // If we reached the end of the document - take the first result
          if (resultIndex > data.results.length - 1) {
            result = data.results[0];
            break;
          }
          // also only check for the first parts of a result
          if (!result.isFirstPart) {
            continue;
          }
          result = data.results[resultIndex];
        }
      }

      if (!result) {
        if (attrs.callback) {
          attrs.callback(false);
        }
        return false;
      }

      replaceToTransaction(tr, attrs.replace, result);
      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'SearchAndReplaceExtension - replace');
      tr.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
      dispatch(tr);

      setTimeout(() => {
        if (attrs.callback) {
          attrs.callback(true);
        }
        if (!editor) {
          return;
        }
        editor.commands.find({searchTerm: data.currentSearchTerm});
      }, 400);
      return true;
    }
  };

  const replaceAll = (data: SearchAndReplaceExtensionData, attrs: { replace: string; searchTerm: string; callback?: (success: boolean) => void }, editor: CoreEditor) => {
    // If there are no search results yet, trigger the search first.
    const searchTriggered = searchIfNoResults(data, attrs.searchTerm, editor);
    if (!searchTriggered) {
      // The search would update the variable holding the current curosr position. Else we do it manually.
      checkCursorPosition(data);
    }

    return ({tr, state, dispatch}: { tr: Transaction, state: EditorState, dispatch: ((args?: any) => any) | undefined }): boolean => {
      if (!data.results.length || !editor || !dispatch) {
        if (attrs.callback) {
          attrs.callback(false);
        }
        return false;
      }

      data.results.forEach((result: SearchAndReplaceResult, index: number) => {
        // Only check for the first parts of a result
        if (result.isFirstPart) {
          replaceToTransaction(tr, attrs.replace, result);
          rebaseNextResult(data, attrs.replace, index);
        }
      })

      tr.setMeta(ProsemirrorTransactionMeta.DISPATCH_SOURCE, 'SearchAndReplaceExtension - replaceAll');
      tr.setMeta(ProsemirrorTransactionMeta.PATENTENGINE_ALLOWED_TRANSFORMATION, true);
      dispatch(tr);

      if (attrs.callback) {
        attrs.callback(true);
      }

      editor.commands.find({searchTerm: data.currentSearchTerm});
      return true;
    };
  };

  const clear = (data: SearchAndReplaceExtensionData) => {
    return ({tr, state, dispatch}: { tr: Transaction, state: EditorState, dispatch: ((args?: any) => any) | undefined }): boolean => {
      // clear the searchTerm caught by Keydown when clearing the sear.
      EditorModule.setSearchTermOnKeyDown('');
      if (!dispatch) {
        return false;
      }
      // If the search is not currently active don't update the view!
      if (data.currentSearchTerm === null && data.results.length === 0) {
        return false;
      }

      data.currentSearchTerm = null;
      data.results = [];

      state.tr.setMeta(ProsemirrorTransactionMeta.CLEAR_SEARCH, true);
      updateView(data, tr, dispatch);
      return true;
    }
  };

  return Extension.create(
    {
      name: 'searchAndReplaceExtension',

      addOptions() {
        return extendedOptions;
      },

      addStorage(): SearchAndReplaceExtensionData {
        return initialData;
      },

      addCommands() {
        return {
          find: (attrs: { searchTerm: string | null }) => {
            return find(this.storage, attrs);
          },
          replace: (attrs: {
            replace: string;
            searchTerm: string;
            callback?: (success: boolean) => void
          }) => replace(this.storage, attrs, this.editor),
          replaceAll: (attrs: {
            replace: string;
            searchTerm: string;
            callback?: (success: boolean) => void
          }) => replaceAll(this.storage, attrs, this.editor),
          clearSearch: () => clear(this.storage)
        }
      },

      addProseMirrorPlugins() {
        return [
          new Plugin(
            {
              state: {
                init() {
                  return DecorationSet.empty;
                },
                apply: (tr: Transaction, old: DecorationSet): DecorationSet => {
                  if (this.storage._updating || this.options.searching || (tr.docChanged && this.options.alwaysSearch)) {
                    this.storage._updating = false;
                    return createDeco(this.storage, tr.doc);
                  }

                  if (tr.docChanged) {
                    return old.map(tr.mapping, tr.doc);
                  }

                  return old;
                }
              },
              props: {
                decorations(state) {
                  return this.getState(state);
                },
                handleKeyDown: (view: { state: EditorState }, event) => {
                  // 114: F3, 70: F
                  if (event.which === 114 || (event.ctrlKey && event.which === 70)) {
                    const docSelection = document.getSelection();
                    if (docSelection) {
                      EditorModule.setSearchTermOnKeyDown(docSelection.toString().trim());
                    }

                    event.preventDefault();
                    EditorModule.setSearchBarVisible(true);
                  }
                  return false;
                }
              }
            })
        ];
      }
    });
};
