import {Node as PmNode, ResolvedPos} from '@tiptap/pm/model';
import {Transaction} from '@tiptap/pm/state';
import {SemanticType} from '@/api/models/editor.model';
import {findDepthOfLogicalBlock} from '@/components/applicationEditor/utils/node.util';
import ApplicationModule from '@/store/modules/ApplicationModule';

enum SearchDirection {
  LEFT,
  RIGHT
}

/**
 * Finds the next textblock in the given document starting at the cursor position considering the search direction
 * @param document The document in which to search
 * @param cursorPosition The position from which the search should start
 * @param direction The direction in which to search. @SearchDirection.LEFT to the beginning of the document, @SearchDirection.RIGHT to
 * the end.
 * @param outer optional, determins if to use the outer border position instead of the inner
 * (eg. right & outer will deliver the end position of the textblock)
 * @param isNotReadOnly optional, determins if to only accept (true) blocks that are not flagged "isReadOnly"
 * @return The first position of a found textblock, -1 if no block is found
 */
function findNextTextblock(document: PmNode, cursorPosition: ResolvedPos | number,
                           direction: SearchDirection, outer?: boolean, isNotReadOnly?: boolean): number {
  let absolutPositionOfNode: ResolvedPos;
  try {
    absolutPositionOfNode = document.resolve((typeof cursorPosition === 'object') ? cursorPosition.pos : cursorPosition);
  } catch (err) {
    // Catch RangeError "position is out of fragment"
    return -1;
  }
  switch (direction) {
    case SearchDirection.LEFT: {
      const searchStart = absolutPositionOfNode.node().type.name == "textBlockNode"
        ? absolutPositionOfNode.start() - 1
        : absolutPositionOfNode.pos;
      for (let i = searchStart; i > 0; i--) {
        const probe: ResolvedPos = document.resolve(i);
        const probedNode: PmNode = probe.node(probe.depth);
        if (probedNode.isTextblock && (!isNotReadOnly || !probedNode.attrs.isReadOnly)) {
          return outer ? probe.start() : probe.end();
        }
      }
      return -1;
    }
    case SearchDirection.RIGHT: {
      const searchStart = absolutPositionOfNode.node().type.name == "textBlockNode"
        ? absolutPositionOfNode.end() + 1
        : absolutPositionOfNode.pos;
      for (let i = searchStart; i < document.nodeSize - 1; i++) {
        const probe: ResolvedPos = document.resolve(i);
        const probedNode: PmNode = probe.node(probe.depth);
        if (probedNode.isTextblock && (!isNotReadOnly || !probedNode.attrs.isReadOnly)) {
          return outer ? probe.end() : probe.start();
        }
      }
      return -1;
    }
  }
  return -1;
}

/**
 * Finds the next valid caret position for the given caret position, provided the search direction.
 * @param document
 * @param cursorPosition
 * @param direction
 * @param isNotReadOnly
 */
function findNextTextPosition(document: PmNode, cursorPosition: ResolvedPos | number, direction: SearchDirection, isNotReadOnly: boolean): number | null {
  let resolvedPos: ResolvedPos;
  try {
    resolvedPos = document.resolve((typeof cursorPosition === 'object') ? cursorPosition.pos : cursorPosition);
  } catch (err) {
    // Catch RangeError "position is out of fragment"
    return null;
  }

  const isEditable = (pos: ResolvedPos): boolean => {
    for (let depth = pos.depth; depth >= 0; depth--) {
      const node = pos.node(depth);
      if (node.isTextblock && (!isNotReadOnly || !node.attrs.isReadOnly)) {
        return true;
      }
      if (node.attrs.isReadOnly) {
        return false;
      }
    }
    return false;
  };

  switch (direction) {
    case SearchDirection.LEFT: {
      for (let i = resolvedPos.pos - 1; i >= 0; i--) {
        const probe = document.resolve(i);
        if (isEditable(probe)) {
          return i;
        }
      }
      return null;
    }
    case SearchDirection.RIGHT: {
      for (let i = resolvedPos.pos + 1; i < document.nodeSize; i++) {
        const probe = document.resolve(i);
        if (isEditable(probe)) {
          return i;
        }
      }
      return null;
    }
    default:
      return null;
  }
}

/**
 * Returns the position from the internal mapping of the given transaction corresponding to the given position.
 * The internal mapping of the transaction will compute the given position taking into account changes done in the document meanwhile.
 * @param transaction The transaction object to compute the position.
 * @param position The position to be used for extracting the correct position within the mapping of the given transaction.
 * @param assoc When given, assoc (should be -1 or 1, defaults to 1) determines with which side the position is associated, which
 * determines in which direction to move when a chunk of content is inserted at the mapped position.
 * @return The position from the internal mapping of the given transaction corresponding to the given position.
 */
function getMappedTransactionPosition(transaction: Transaction, position: number, assoc?: number): number {
  return transaction.mapping.map(position, assoc);
}

const semanticTypesExcludedFromSpellcheck = [
  SemanticType.SHORT_DESCRIPTION_FIGURE_LIST, SemanticType.SHORT_DESCRIPTION_FIGURE, SemanticType.SHORT_DESCRIPTION_FIGURE_TEXT,
  SemanticType.REFERENCE_SIGN_LIST, SemanticType.REFERENCE_SIGN_LIST_ROW, SemanticType.REFERENCE_SIGN_LIST_ROW_NAME
];

function isNodeExcludedFromSpellcheck(node: PmNode): boolean {
  return semanticTypesExcludedFromSpellcheck.includes(node.attrs.semanticType as SemanticType);
}


function hasLogicalBlockAsDescendant(node: PmNode): boolean {
  let result = false;
  node.descendants((_node: PmNode, pos: number) => {
    if (_node.attrs.logicalBlock) {
      result = true;
      return false;
    }
  })

  return result;
}

/**
 * Desides if the given node should be specllchecked.
 * @param node PmNode to check
 */
function shouldSpellcheckLogicalBlock(node: PmNode): boolean {
  return (node.type.name === 'structuralBlockNode' || node.type.name === 'structuralInlineBlockNode' || node.type.name === 'tableEntryNode')
    && node.attrs.logicalBlock && !hasLogicalBlockAsDescendant(node) && !isNodeExcludedFromSpellcheck(node);
}

/**
 * Finds all logical blocks under the given Prosemirror Node that don't have other logical blocks as children,
 * except the blocks we don't want to spell-check (see isNodeExcludedFromSpellcheck)
 * @param node The Prosemirror Node from which to search
 * @return list of all logical blocks in the given document
 */
function findAllLogicalBlocksLeaves(node: PmNode): PmNode[] {

  const list: PmNode[] = [];

  node.descendants((node: PmNode, pos: number) => {
    if (shouldSpellcheckLogicalBlock(node)) {
      list.push(node);
    }
  })
  return list;
}

/**
 * Finds all text nodes under the given Prosemirror Node.
 * @param node The Prosemirror Node from which to search
 * @return list of all text nodes in the given node
 */
function findAllTextNodes(node: PmNode): PmNode[] {

  const list: PmNode[] = [];

  node.descendants((node: PmNode, pos: number) => {
    if (node.isTextblock) {
      node.content.forEach(textNode => list.push(textNode));
    }
  })
  return list;
}

function calcLogicalBlock(root: PmNode, pos: number): PmNode | null {
  let resolvedPos: ResolvedPos;
  try {
    resolvedPos = root.resolve(pos);
  } catch (err) {
    // Catch RangeError "out of range"
    return null;
  }
  const depth = findDepthOfLogicalBlock(resolvedPos);
  if (depth < 0) {
    return null;
  }
  return resolvedPos.node(depth);
}

/**
 * Determine the position in the document where we can start typing.
 * It will cache the result in the ApplicationModule. This will be reused until "currentApplicationDocument" is replaced.
 * @param PmNode prosemirror node (eg. the root) to start from
 * @return start position of the document
 */
function determineDocStartPos(PmNode: PmNode): number {
  let startPos = ApplicationModule.currentAppDocStartPos;
  if (startPos != null) {
    return startPos;
  }

  startPos = -1;
  let currentPos = PmNode.nodeSize / 2;

  while (currentPos != -1) {
    startPos = currentPos;
    currentPos = findNextTextblock(PmNode, currentPos, SearchDirection.LEFT, true);
  }

  ApplicationModule.setCurrentAppDocStartPos(startPos);
  return startPos;
}

export {
  findNextTextblock, findNextTextPosition, SearchDirection, getMappedTransactionPosition, hasLogicalBlockAsDescendant, shouldSpellcheckLogicalBlock,
  findAllLogicalBlocksLeaves, findAllTextNodes, isNodeExcludedFromSpellcheck, calcLogicalBlock, determineDocStartPos
}
